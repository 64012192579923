import React from 'react';
import Api from '../../../js/Api';
import Utilities from '../../../js/Utilities';
import StickerTable from '../../Common/StickerTable/StickerTable';
import Totals from '../../Common/Totals/Totals';
import ReportsHeader from '../../Common/ReportsHeader/ReportsHeader';
import './Reports.css';
import '../../Common/StatsTable/StatsTable.css';
import Loader from '../../Common/Loader/Loader';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';


const BRANCH_NAME = "Got Tea - Nirvana";
const Client = new Api();
const moment = require('moment-timezone');
class Reports extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            loading:true,
            browser : Utilities.getCurrentBrowser(),
            money : 0.0,
            data : null,
            stats : null,
            stickerItems : []
        }
    }

    loadDrinks = async ()=>{
        const {startDate, endDate } = this.state;
        this.setState({
            loading:true
        },()=>{
            Client.getRecentDrinks(BRANCH_NAME).then((stickerData)=>{
                this.setState({
                    stickerItems:stickerData,
                    loading:false
                });
                
            }).catch((err)=>{
                console.log('GET GOT TEA DATA ERROR =>', err);
            });
        })
    }


    componentDidMount(){
        let mainThis = this;
        firebase.auth().onAuthStateChanged(user => {
            let loggedIn = !!user;
            if(loggedIn){
                this.loadDrinks();
            } else {
                window.location.href = '/login';
            }
        });
        mainThis.loadDrinks();
    }

    componentWillUnmount(){
    }

    setStartDate = (e) => {
        this.setState({startDate : e.target.value},()=>{
        });
    }

    onUpdateClick = ()=>{
        this.loadOrders();
    }

    setEndDate = (e) => {
        this.setState({endDate : e.target.value},()=>{
        });
    }

    render(){
        const { 
            stickerItems,
            loading,
         } = this.state;
        
     
        return <div className='reportContainer'>
            <ReportsHeader/>
            <div className='wrapper'>
                <StickerTable loading={loading} title={'Stickers Today'} showLargeAndSmall={true} data={stickerItems}/>      
            </div>
            </div>
    } 
}

export default Reports;